<template>
  <div>
    <v-sheet color="grey lighten-3" min-height="10vh">
      <v-container fill-height>
        <v-row dense justify="start">
          <div class="transition-swing text-h4 mt-10 mb-2">Pricing</div>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="grey lighten-4">
      <v-container>
        <v-row>
          <v-col lg="3" md="6" sm="6" cols="12">
            <merchantselect :enableSelectAll="true" />
          <v-alert type="warning" dense v-if="merchants.length == 0">
            <div class="transition-swing text-caption mb-4">All merchants included</div>
          </v-alert>
          </v-col>
          <v-col lg="4" md="6" cols="12">
            <v-card min-height="120">
              <v-card-subtitle class="my-0"> Date range </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col lg="6" md="6" sm="6" cols="12">
                    <v-menu
                      v-model="dateFromMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFrom"
                          label="Date from"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFrom"
                        @input="dateFromMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col lg="6" md="6" sm="6" cols="12">
                    <v-menu
                      v-model="dateToMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateTo"
                          label="Date to"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateTo"
                        @input="dateToMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
            <v-btn-toggle  @change="setPeriod" color="primary" group dense>
              <v-btn value="ytd" small>YTD</v-btn>

              <v-btn value="lytd" small>LYTD</v-btn>

              <v-btn value="l12" small>L12</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col> </v-col>
        </v-row>
        <v-divider class="my-2" />
        <v-row justify="start">
          <v-col cols="2">
            <v-btn color="primary" @click="refresh" small> Refresh </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="grey lighten-5">
      <v-container>
        <v-row justify="center">
          <v-col lg="10" md="6" cols="12">
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                :loading="loading"
                class="elevation-1"
                item-key="id"
                show-expand
                :single-expand="true"
                :expanded.sync="expanded"
                v-if="items.length > 0"
                :search="search"
              >
                            <template v-slot:top>
                <v-toolbar flat>
                  <v-container>
                    <v-row class="mt-1" justify="center" align="top">
                      <v-toolbar-title>Report</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </v-row>
                  </v-container>
                </v-toolbar>
              </template>
              <template v-slot:no-data>
                <v-container>
                  <v-row justify="center" align="center">
                    <v-col cols="2">
                  <v-alert
                    dense
                    outlined
                    type="warning">
                      Click refresh to fetch the report
                  </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length">
                <v-card rounded class="ma-1" flat color="primary" dark>
                  <v-simple-table
                    dense
                    class="pa-5"
                    style="background-color: rgba(0, 0, 0, 0)"
                  >
                    <thead>
                      <td>Product</td>
                      <td>Quantity</td>
                      <td>Amount</td>
                      <td>Rebate</td>
                    </thead>
                    <tbody>
                      <tr
                        v-for="p in item.products"
                        :key="p.productId"
                      >
                        <td>{{ p.productName }}</td>
                        <td>{{ p.quantity_Formatted }}</td>
                        <td>{{ p.revenue_Formatted }}</td>
                        <td>{{ p.rebate_Formatted }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </td>
            </template>
              </v-data-table>
           
          </v-col>
        </v-row>

      </v-container>
    </v-sheet>
  </div>
</template>


<script>
import merchantselect from "@/components/filters/autocompletes/MerchantSelect.vue";
import merchantService from "@/services/MerchantService";

export default {
  components: {
    merchantselect
  },
  data: () => ({
    search: "",
    dateFrom: null,
    dateFromMenu: false,
    dateTo: null,
    dateToMenu: false,
    loading: false,
    loadingDownload: false,
    items: [],
    merchantAggregated: [],
    expanded: false,
    headers: [
      {
        text: "Merchant",
        sortable: false,
        value: "merchantName",
        align: "start",
      },
      {
        text: "Card Class",
        sortable: false,
        value: "cardClass",
        align: "start",
      },      
      {
        text: "Quantity",
        sortable: false,
        value: "quantity_Formatted",
        align: "start",
      },
      {
        text: "Revenue",
        sortable: false,
        value: "revenue_Formatted",
        align: "start",
      },
      {
        text: "Rebate",
        sortable: false,
        value: "rebate_Formatted",
        align: "start",
      },
    ],
  }),
  mounted() {
    this.setPeriod("ytd")
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    merchants() {
      return this.$store.getters["filter/filterRequest"].merchants;
    },
  },
  methods: {
    setPeriod(val) {
      let today = new Date();
      switch (val) {
        case "ytd":
          this.dateFrom = formatDate( new Date(today.getFullYear(), 0, 1) );
          this.dateTo = formatDate(today);
          break;
        case "lytd":
          this.dateFrom = formatDate( new Date(today.getFullYear() - 1, 0, 1));
          this.dateTo = formatDate( addDays(today, -365));
          break;
        case "l12":
          this.dateFrom = formatDate( addDays(today, -365));
          this.dateTo = formatDate( today);
          break;
        default:
          break;
      }
    },
    async refresh() {
      let request = {
        merchants: this.merchants,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
      console.log(request);
      this.$store.dispatch("util/setLoading", {
        visible: true,
        text: "Fetching report",
      });
      let result = await merchantService.GetPricingReport(request);
      this.$store.dispatch("util/resetLoading");

      this.items = result;
    },
  },
};

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
</script>




